import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useState, useEffect, useContext, useRef } from "react";
import ImageGallery from "react-image-gallery";
import request from "graphql-request";
import "../assets/css/singleRealization.scss";
import Layout from "../components/layout";
import SingleRealizationCard from "../components/singleRealizationCard";
import chevronBottom from "../images/chevron-down.svg";
import chevronLeft from "../images/chevron-left-white.svg";
import chevronLeftBlack from "../images/chevron-left.svg";
import chevronRightWhite from "../images/chevron-right-btn-white.svg";
import chevronRight from "../images/chevron-right-white.svg";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import { NavContext } from "../layouts";
import { navigate } from "gatsby";
import SEO from "../components/seo";

function SingleRealization({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [anothers, setAnothers] = useState([]);
  const [realizationText, setRealizationText] = useState({});
  const { navData, setNavData } = useContext(NavContext);
  const strapiHost = process.env.GATSBY_API_URL;

  const LOCALE = {
    en: {},
    pl: {},
  };
  const fetch = async () => {
    const res = await request(
      process.env.GATSBY_GRAPHQL_URL,
      `{
        realizations (limit: 3,where:{slug_ne:"${pageContext.slug}"}) {
          title
          logo{
            url
          }
          thumbnailOptionalLogo{
            url
          }
          slug
      }
      realizationsConnection(locale:"${pageContext.intl.language}",where:{slug:"${pageContext.slug}"}){
        values{
          title
          subtitle
          logo{
            url
          }
          hours
          description
          gallery{
            url
          }
          slug
          realization_technologies{
            title
            avatar {
              url
            }
           
          }
          realization_users{
            title
            avatar {
              url
            }
            role
     
          }
          pageLink
          gradient
          text_color
        }
      }
        realizationPage(locale:"${pageContext.intl.language}"){
          realizationTime
          realizationTeam
          realizationTechnologies
          watchMore
          goToWebsite
          projectDescription
          projectGallery
          anotherRealizations
          back
        }
    }
      `
    );
    return res;
  };

  const restProjectRef = useRef();

  useEffect(() => {
    fetch().then((items) => {
      setData(items.realizationsConnection.values[0]);
      setAnothers(items.realizations);
      setRealizationText(items.realizationPage);
      setNavData({
        navBg: "none",
        color: items.realizationsConnection.values[0].text_color,
      });
      setLoading(false);
    });
  }, []);

  return (
    <>
      <SEO
        seo={{
          metaTitle: pageContext.seo.metaTitle,
          metaDescription: pageContext.seo.metaDesc,
        }}
      />
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout
            initial={false}
            contactUs={false}
            navBg="none"
            style={{
              background: data.gradient,
              top: "-75px",
              paddingTop: "75px",
            }}
          >
            <div className="project">
              <div className="project__hero">
                <h2
                  className="project__hero-title"
                  style={{ color: data.text_color }}
                >
                  {data.subtitle}
                </h2>
                <div className="project__hero-container">
                  <div className="image-container">
                    <img
                      className="image-container__img"
                      src={`${strapiHost}${data.logo.url}`}
                    />
                    <h3
                      className="image-container__desc"
                      style={{ color: data.text_color }}
                    >
                      {data.title}
                    </h3>
                  </div>
                  <div className="desc-container">
                    <div className="desc-container__single">
                      <h4
                        className="desc-container__single-title"
                        style={{ color: data.text_color }}
                      >
                        {realizationText.realizationTime}
                      </h4>
                      <p
                        className="desc-container__single-value"
                        style={{ color: data.text_color }}
                      >
                        {data.hours} h.
                      </p>
                    </div>
                    {[
                      {
                        title: realizationText.realizationTeam,
                        elements: data.realization_users,
                      },
                      {
                        title: realizationText.realizationTechnologies,
                        elements: data.realization_technologies,
                      },
                    ].map(({ title, elements }, key) => (
                      <div className="desc-container__single" key={key}>
                        <h4
                          className="desc-container__single-title"
                          style={{ color: data.text_color }}
                        >
                          {title}
                        </h4>
                        <Splide
                          options={{
                            width: 400,
                            height: 150,
                            perPage: 3,
                            type: "loop",
                            arrows: true,
                            focus: "center",
                            autoplay: false,
                            interval: 100,
                            pagination: false,
                            pauseOnHover: false,
                            pauseOnFocus: false,
                            breakpoints: {
                              400: {
                                perPage: 1,
                                width: 200,
                              },
                            },
                          }}
                          renderControls={() => (
                            <div className="splide__arrows">
                              <div
                                className=" splide__arrow--prev"
                                role="button"
                              >
                                <svg
                                  id="svg4"
                                  version="1.1"
                                  fill="none"
                                  viewBox="0 0 11 18"
                                  height="18"
                                  width="11"
                                >
                                  <path
                                    id="path2"
                                    fill={data.text_color}
                                    d="m 9.9090902,0.37657413 c 0.5021028,0.5021 0.5021028,1.31609997 0,1.81829997 l -6.8051521,6.8051 6.8051511,6.8051499 c 0.5021028,0.5021 0.5021028,1.316172 0,1.818273 -0.502102,0.502104 -1.316171,0.502104 -1.818271,0 L 0.37652812,9.9091141 c -0.50213,-0.50211 -0.50213,-1.31617 0,-1.81828 L 8.0908182,0.37657413 c 0.5021,-0.5021 1.31617,-0.5021 1.818272,0 z"
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div
                                className="splide__arrow--next"
                                role="button"
                              >
                                <svg
                                  id="svg4"
                                  version="1.1"
                                  fill="none"
                                  viewBox="0 0 11 18"
                                  height="18"
                                  width="11"
                                >
                                  <path
                                    id="path2"
                                    fill={data.text_color}
                                    d="m 9.9090902,0.37657413 c 0.5021028,0.5021 0.5021028,1.31609997 0,1.81829997 l -6.8051521,6.8051 6.8051511,6.8051499 c 0.5021028,0.5021 0.5021028,1.316172 0,1.818273 -0.502102,0.502104 -1.316171,0.502104 -1.818271,0 L 0.37652812,9.9091141 c -0.50213,-0.50211 -0.50213,-1.31617 0,-1.81828 L 8.0908182,0.37657413 c 0.5021,-0.5021 1.31617,-0.5021 1.818272,0 z"
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                          className="desc-container__single-slide"
                        >
                          {elements.map((item, key) => {
                            return (
                              <SplideSlide
                                className="desc-container__single-slide-element"
                                key={key}
                              >
                                <div className="desc-container__single-slide-element-image">
                                  <img
                                    src={`${strapiHost}${item.avatar.url}`}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                                <h5
                                  className="desc-container__single-slide-element-title"
                                  style={{ color: data.text_color }}
                                >
                                  {item.title}
                                </h5>
                                <p
                                  className="desc-container__single-slide-element-subtitle"
                                  style={{ color: data.text_color }}
                                >
                                  {item.role}
                                </p>
                              </SplideSlide>
                            );
                          })}
                        </Splide>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="project__hero-buttons">
                  <button
                    className="btn btn-light"
                    onClick={() => navigate(-1)}
                  >
                    <img src={chevronLeftBlack} />
                    {realizationText.back}
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      restProjectRef.current.scrollIntoView({ block: "start" });
                    }}
                  >
                    {realizationText.watchMore}
                    <img src={chevronBottom} />
                  </button>
                  <a href={data.pageLink} target="_blank">
                    <button className="btn btn-primary">
                      {realizationText.goToWebsite}
                      <img src={chevronRightWhite} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Layout>
          <Layout nav={false} initial={false} contactUs={false}>
            <div className="project" ref={restProjectRef}>
              <div className="project__details">
                <h2 className="project__details-title">
                  {realizationText.projectDescription}
                </h2>
                <p className="project__details-desc">{data.description}</p>
              </div>
              <div className="project__gallery">
                <h2 className="project__details-title">
                  {realizationText.projectGallery}
                </h2>
                <ImageGallery
                  items={data.gallery.map(({ url }, key) => ({
                    key: key,
                    original: strapiHost + url,
                    thumbnail: strapiHost + url,
                  }))}
                />
              </div>
            </div>
          </Layout>
          <div className="project__other-realizations">
            <Layout nav={false} initial={false} contactUs={false}>
              <h2 className="project__other-realizations-title">
                {realizationText.anotherRealizations}
              </h2>
              <div className="project__other-realizations-container">
                {anothers.map(
                  ({ logo, slug, title, thumbnailOptionalLogo }, key) => (
                    <SingleRealizationCard
                      key={key}
                      title={title}
                      img={
                        thumbnailOptionalLogo
                          ? thumbnailOptionalLogo.url
                          : logo.url
                      }
                      to={slug}
                    />
                  )
                )}
              </div>
            </Layout>
          </div>
          <Layout nav={false} initial={false} contactUs={false} />
        </ShowProvider>
      )}
    </>
  );
}

export default SingleRealization;
